import React from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as Styles from "../styles/effect.module.css";
import Cta from "../components/cta";
import { StaticImage } from "gatsby-plugin-image";

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const promotionPost = data.promotionPost.nodes;
  const posts = data.post.nodes;

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />

        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    );
  }

  return (
    <Layout>
      <Seo title="All Fleet Management news" />

      <div className="relative pt-24 md:pt-36 bg-bluebg">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/blog-background-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0 z-0"
        />

        <div className="max-w-6xl mx-auto">
          <h1 className="relative z-10 pt-12 text-2xl font-bold text-center text-white md:pt-0 md:text-3xl">
            Fleet Management News & Updates
          </h1>
          <p className="relative z-10 px-4 mt-4 text-center text-white md:text-base md:px-0">
            Stay updated on latest fleet solutions and industry news
          </p>
          <div className="relative z-30 max-w-6xl p-8 mx-auto mt-16 bg-white md:rounded ">
            {promotionPost.map((post) => {
              return (
                <div className="flex flex-col-reverse justify-between md:flex-row">
                  <div className="md:w-2/3 md:pr-8">
                    <h2 className="mt-4 mb-4 text-3xl font-bold md:mt-0 text-bluetext">
                      {post.title}
                    </h2>
                    {parse(post.excerpt)}
                    <Link
                      className="inline-flex px-5 py-2 mt-6 text-sm text-white rounded-full hover:bg-primary-light bg-primary"
                      to={post.uri}
                    >
                      Read more
                    </Link>
                  </div>
                  <div className="md:w-1/3">
                    <div className="relative w-full h-auto overflow-hidden rounded">
                      <Link to={post.uri}>
                        <img
                          src={post.featuredImage?.node?.sourceUrl
                            .replace("cms.katsana.com", "web-cdn.katsana.com")
                            .replace(/jpg|png|jpeg/gi, "webp")}
                          alt={post.featuredImage?.node?.altText || `katsana`}
                          className="transition-transform featured-image hover:transform hover:scale-110"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
      </div>

      <div className="bg-[hsla(204,26%,93%,1)] pt-12 pb-24">
        {/* Latest Articles */}
        <div className="max-w-6xl px-8 mx-auto md:px-0">
          <div className="grid grid-cols-6 gap-6">
            {posts.map((post) => {
              return (
                <div className="col-span-6 md:col-span-2">
                  <div className="relative rounded-tl rounded-tr">
                    <div className="relative w-full h-auto overflow-hidden rounded-tl rounded-tr">
                      <Link to={post.uri}>
                        <img
                          src={post.featuredImage?.node?.sourceUrl
                            .replace("cms.katsana.com", "web-cdn.katsana.com")
                            .replace(/jpg|png|jpeg/gi, "webp")}
                          alt={post.featuredImage?.node?.altText || `katsana`}
                          className="transition-transform rounded-tl rounded-tr featured-image hover:transform hover:scale-110"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="p-4 bg-white rounded">
                    <h2>
                    <Link
                      to={post.uri}
                      className="block my-4 text-xl font-medium"
                    >
                      {post.title}
                    </Link>
                    </h2>
                    <p className="text-sm leading-6 excerpt">
                      {parse(post.excerpt)}
                    </p>
                    <div className="flex justify-end mt-10 text-sm">
                      <p>{post.date}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-between mt-8 mb-16">
            {previousPagePath && (
              <>
                <Link
                  to={previousPagePath}
                  rel="prev"
                  className="inline-flex px-5 py-2 mt-6 text-sm text-white rounded-full hover:bg-primary-light bg-primary"
                >
                  Previous page
                </Link>
              </>
            )}
            {nextPagePath && (
              <Link
                to={nextPagePath}
                rel="next"
                className="inline-flex px-5 py-2 mt-6 text-sm text-white rounded-full hover:bg-primary-light bg-primary"
              >
                Next page
              </Link>
            )}
          </div>
        </div>
      </div>

      <Cta />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    # Promotion Post
    promotionPost: allWpPost(
      limit: 1
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Promotion" } } } }
      }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }

    # All Post
    post: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {categories: {nodes: {elemMatch: {name: {nin: ["FrontpageNews"]}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`;
